import React, { createContext, useContext, useState } from 'react';

const FileContext = createContext();

export function FileProvider({ children }) {
  const [file, setFile] = useState(null);
  const [file2, setFile2] = useState(null);
  const [file4, setFile4] = useState(null);

  return (
    <FileContext.Provider value={{ file, setFile, file2, setFile2, file4, setFile4 }}>
      {children}
    </FileContext.Provider>
  );
}

export function useFile() {
  return useContext(FileContext);
}

