/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2023-11-04 11:31:26
 * @LastEditTime: 2023-11-04 11:34:26
 * @Description: 
 */

import React, { useEffect } from 'react';

function FinalStep({ data, submitForm, nextStep }) {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])



  // Display a summary of the user's selections and uploaded files
  return (
    <div class="center">
      <h2>Thanks for the submit</h2>
      <p>Here is your application no : AB123456</p>
    </div>
  );
}

export default FinalStep;