import React, { useState, useEffect } from 'react';

function Timer() {
  const [remainingTime, setRemainingTime] = useState(180000); // 3 minutes in milliseconds

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (remainingTime > 0) {
        setRemainingTime((prevTime) => prevTime - 1000); // Subtract 1 second

        if (remainingTime === 0) {
          clearInterval(timerInterval);
          // Handle timer expiration here, e.g., display a message or perform an action
        }
      }
    }, 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(timerInterval);
  }, [remainingTime]);

  return (
    <div className="timer">
      Time Remaining: {formatTime(remainingTime)}
    </div>
  );
}

// Helper function to format milliseconds into minutes and seconds
function formatTime(milliseconds) {
  const minutes = Math.floor(milliseconds / 60000);
  const seconds = ((milliseconds % 60000) / 1000).toFixed(0);
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
}

export default Timer;
