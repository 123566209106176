import React, { useState, useEffect } from 'react';
import { useFile } from './FileContext';
import { saveImageToStorageStep4, loadImageFromStorageStep4 } from './ImageStorage';
import { saveChoiceToStorageStep4, loadChoiceFromStorageStep4 } from './ChoiceStorage';

function Step4({ data, setData, nextStep, prevStep }) {


  const [selectedChoice, setSelectedChoice] = useState(loadChoiceFromStorageStep4() || '');
  const { file4, setFile4 } = useFile();
  const [filePreview, setFilePreview] = useState(loadImageFromStorageStep4());

  useEffect(() => {
    saveImageToStorageStep4(file4);
  }, [file4])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleTextChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  const handleChoiceChange = (choice) => {
    setSelectedChoice(choice);
    setData({ ...data, step4Option: choice });
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile4(selectedFile);

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setFilePreview(event.target.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleNextClick = () => {
    if (isNextButtonEnabled) {
      saveChoiceToStorageStep4(selectedChoice);
      nextStep();
    } else {
      alert("Please complete all fields and upload a file before proceeding.");
    }
  }

  //const isNextButtonEnabled = selectedChoice && file4 && data.job;
  const isNextButtonEnabled = selectedChoice && data.job;

  const options = [
    "自僱",
    "合約／長工",
    "兼職",
    "待業",
  ];

  const imgStyle = {
    maxWidth: 200,
  };

  return (
    <div class="center">
      <h2>Step 4: Upload and enter jobs details</h2>
      {options.map((option, index) => (
        <div key={index}>
          <input
            type="radio"
            id={option}
            name="options"
            value={option}
            checked={selectedChoice === option}
            onChange={() => handleChoiceChange(option)}
          />
          <label htmlFor={option}>{option}</label>
        </div>
      ))}
      <br />
      <div class="inputbox">
        <input
          autocomplete="false"
          required="required"
          type="text"
          name="job"
          value={data.job}
          onChange={handleTextChange}
        />
        <span>Title</span>
      </div>
      <input type="file" onChange={handleFileChange} />

      {file4 === null && (
        <div>
          <img src="https://download.cnet.com/a/neutron/images/error/404download.jpg" alt="File Preview" style={imgStyle} />
        </div>
      )}
      {file4 && (
        <div>
          {file4.type === 'application/pdf' ? (
            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png" alt="File Preview" style={{ maxWidth: '30%' }} />
          ) : (
            <img src={filePreview} alt="File Preview" style={imgStyle} />
          )}
        </div>
      )}
      <button className="step_back" onClick={prevStep}>Back</button>
      {isNextButtonEnabled && <button className="step" onClick={handleNextClick}>Next</button>}
      <br /><br />
    </div>
  );
}

export default Step4;
