/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2023-11-03 16:25:36
 * @LastEditTime: 2023-11-06 18:51:39
 * @Description: 
 */
import React, { useState, useEffect } from 'react';
import { useFile } from './FileContext';
import { saveImageToStorage, loadImageFromStorage } from './ImageStorage';


function Step2({ nextStep, data, setData, prevStep }) {
  const { file, setFile } = useFile();
  const [filePreview, setFilePreview] = useState(loadImageFromStorage());
  const [apiResponse, setApiResponse] = useState(null);

  useEffect(() => {
    saveImageToStorage(file);
  }, [file]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFilePreview("https://i.gifer.com/ZKZg.gif");
    // Read the selected file and set the file preview
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        console.log(apiResponse);
      };
      reader.readAsDataURL(selectedFile);

      try {
        const formData = new FormData();
        formData.append('file', selectedFile);

        const response = await fetch('https://ocr3.kinfaat.com/upload', {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const res = await response.json();
        console.log(res);
        if (res.statusCode === 1) {
          console.log(res.data);
          setData({ ...data, name: res.data.nameCn, age: res.data.age, idNumber: res.data.idNumber, sex: res.data.sex });
        }
        setApiResponse(res);
      } catch (error) {
        console.error('Error:', error);
        setApiResponse({ statusCode: 0, message: 'Error uploading image' });
      } finally {
        reader.onload = (event) => {
          setFilePreview(event.target.result);
        };
        reader.readAsDataURL(selectedFile);
      }
    }
  }


  const handleTextChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const isNextButtonEnabled = file && data.name && data.age && data.idNumber && data.sex;

  const handleNextClick = () => {
    if (isNextButtonEnabled) {
      nextStep();
    } else {
      alert("Please complete all fields and upload a file before proceeding.");
    }
  }

  const imgStyle = {
    maxWidth: 200,
  };

  return (
    <div className="center">
      <form autoComplete="off">
        <h2>Step 2: Upload ID</h2>
        <input
          type="file"
          onChange={handleFileChange}
          accept="image/*;capture=camera"
        />
        {file === null && (
          <div>
            <img src="https://download.cnet.com/a/neutron/images/error/404download.jpg" alt="File Preview" style={imgStyle} />
          </div>
        )}
        {file && (
          <div>
            {file.type === 'application/pdf' ? (
              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png" alt="File Preview" style={{ maxWidth: '30%' }} />
            ) : (
              <img src={filePreview} alt="File Preview" style={imgStyle} />
            )}
          </div>
        )}

        <br />
        <div className="inputbox">
          <input
            autoComplete="false"
            required="required"
            type="text"
            name="name"
            value={data.name}
            onChange={handleTextChange}
          />
          <span>Name</span>
        </div>

        <div className="inputbox">
          <input
            autoComplete="false"
            required="required"
            type="number"
            name="age"
            value={data.age}
            onChange={handleTextChange}
          />
          <span>Age</span>
        </div>

        <div className="inputbox">
          <input
            autoComplete="false"
            required="required"
            type="text"
            name="idNumber"
            value={data.idNumber}
            onChange={handleTextChange}
          />
          <span>ID Number</span>
        </div>

        <div className="inputbox">
          <input
            autoComplete="false"
            required="required"
            type="text"
            name="sex"
            value={data.sex}
            onChange={handleTextChange}
          />
          <span>Sex</span>
        </div>


        <button className="step_back" onClick={prevStep}>Back</button>
        {isNextButtonEnabled && <button className="step" onClick={handleNextClick}>Next</button>}
      </form>
    </div>
  );

}

export default Step2;
