import React, { useState, useEffect } from 'react';
import { useFile } from './FileContext';
import { saveImageToStorageStep3, loadImageFromStorageStep3 } from './ImageStorage';
import { saveChoiceToStorageStep3, loadChoiceFromStorageStep3 } from './ChoiceStorage';


function Step3({ data, setData, nextStep, prevStep }) {
  //const [selectedOption, setSelectedOption] = useState('');
  const [selectedChoice, setSelectedChoice] = useState(loadChoiceFromStorageStep3() || '');
  const { file2, setFile2 } = useFile();
  const [filePreview, setFilePreview] = useState(loadImageFromStorageStep3());

  useEffect(() => {
    saveImageToStorageStep3(file2);
  }, [file2]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleChoiceChange = (choice) => {
    setData({ ...data, step3Option: choice });
    setSelectedChoice(choice);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile2(selectedFile);

    // Read the selected file and set the file preview
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setFilePreview(event.target.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  //const isNextButtonEnabled = selectedChoice && file2;
  const isNextButtonEnabled = selectedChoice;

  const handleNextClick = () => {
    if (isNextButtonEnabled) {
      saveChoiceToStorageStep3(selectedChoice);
      nextStep();
    } else {
      alert("Please complete all fields and upload a file before proceeding.");
    }
  }

  const options = [
    "租",
    "自置",
    "家人同住",
    "其他",
  ];

  const imgStyle = {
    maxWidth: 200,
  };

  return (
    <div class="center">
      <h2>Step 3: Upload Address and select live status</h2>
      {options.map((option, index) => (
        <div key={index}>
          <input
            type="radio"
            id={option}
            name="options"
            value={option}
            checked={selectedChoice === option}
            onChange={() => handleChoiceChange(option)}
          />
          <label htmlFor={option}>{option}</label>
        </div>
      ))}

      <input type="file" onChange={handleFileChange} />
      {file2 === null && (
        <div>
          <img src="https://download.cnet.com/a/neutron/images/error/404download.jpg" alt="File Preview" style={imgStyle} />
        </div>
      )}
      {file2 && (
        <div>
          {file2.type === 'application/pdf' ? (
            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png" alt="File Preview" style={{ maxWidth: '30%' }} />
          ) : (
            <img src={filePreview} alt="File Preview" style={imgStyle} />
          )}
        </div>
      )}
      <button className="step_back" onClick={prevStep}>Back</button>
      {isNextButtonEnabled && <button className="step" onClick={handleNextClick}>Next</button>}
    </div>
  );
}

export default Step3;
