import React from 'react';

function WhatsAppIcon() {
  return (
    <div className="whatsapp-icon">
      <a
        href="https://wa.me/97733938" // Replace with your WhatsApp number
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="/images/whatsapp.png" // Replace with the correct path to your WhatsApp icon
          alt="WhatsApp"
          className="whatsapp-icon-img"
        />
      </a>
    </div>
  );
}

export default WhatsAppIcon;