/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2023-11-03 16:25:36
 * @LastEditTime: 2023-11-06 18:43:47
 * @Description: 
 */
import React, { useState, useEffect } from 'react';
import { loadImageFromStorage, loadImageFromStorageStep3, loadImageFromStorageStep4 } from './ImageStorage';

function FinalStep({ data, submitForm, nextStep, prevStep }) {

  const [filePreview] = useState(loadImageFromStorage());
  const [filePreview3] = useState(loadImageFromStorageStep3());
  const [filePreview4] = useState(loadImageFromStorageStep4());

  const imgStyle = {
    maxWidth: 400,
  };

  const handleNext = () => {
    nextStep();
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  // Display a summary of the user's selections and uploaded files
  return (
    <div class="center">
      <h2>Final Step: Confirm and Submit</h2>
      {/* Display the form data for confirmation */}
      {console.log(data)}
      <ul>
        <li>Step1 Option1: {data.step1Option}</li>
        <li>Step1 Option2: {data.step1Option2}</li>
        <li>Step2 Name: {data.name}</li>
        <li>Step2 Age: {data.age}</li>
        <li>Step3 Option: {data.step3Option}</li>
        <li>Step3 Job: {data.job}</li>
        <li>Step4 Option: {data.step4Option}</li>
        <li>Step5 Phone: {data.phoneNumber}</li>
        <li>Step5 Bank: {data.bank}</li>
      </ul>
      <p>Step 2 File</p>
      <img src={filePreview} alt="File Preview" style={imgStyle} />
      <p>Step 3 File</p>


      {filePreview3 && (
        <div>
          {filePreview3.type === 'application/pdf' ? (
            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png" alt="PDF" style={{ maxWidth: '30%' }} />
          ) : (
            <img src={filePreview3} alt="File Preview3" style={imgStyle} />
          )}
        </div>
      )}
      <p>Step 4 File</p>
      <img src={filePreview4} alt="File Preview" style={imgStyle} />
      <br /><br />
      <button className="step_back" onClick={prevStep}>Back</button>
      <button className="step" onClick={handleNext}>Confirm and Submit</button>
    </div>
  );
}

export default FinalStep;