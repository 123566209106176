/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2023-11-04 09:55:24
 * @LastEditTime: 2023-11-04 11:48:27
 * @Description: 
 */
import React, { useState } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import FinalStep from './FinalStep';
import Submited from './Submited';
import { FileProvider } from './FileContext';
import WhatsAppIcon from './component/WhatsAppIcon'; // 
import Timer from './component/Timer'; // Import the Timer component
import './App.css'; // Import the CSS file
import './css/Step.css';
import './css/Whatsapp.css';
import './css/Timer.css';
import { saveChoiceToStorage, saveChoiceToStorage2 } from './ChoiceStorage';


function App() {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    age: '',
    job: '',
    idNumber: '',
    sex: '',
    phoneNumber: '',
    bank: '',
    step1Option: '',
    step3Option: '',
    step4Option: '',
    step1Option2: ''
    // Other form fields
  });

  const clearLocalStorage = () => {
    // Clear specific items from localStorage
    localStorage.clear();
    localStorage.removeItem('selectedChoice');
    localStorage.removeItem('selectedChoice2');
    saveChoiceToStorage(null);
    saveChoiceToStorage2();

    //localStorage.removeItem('imagePreview');
    //localStorage.removeItem('selectedChoiceStep3');
    //localStorage.removeItem('uploadedImage');
    //localStorage.removeItem('uploadedImageStep3');
  };
  window.addEventListener('beforeunload', clearLocalStorage);


  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  // Render the appropriate step based on the currentStep
  let stepComponent;
  switch (currentStep) {
    case 1:
      stepComponent = <Step1 nextStep={nextStep} data={formData} setData={setFormData} />;
      break;
    case 2:
      stepComponent = <Step2 nextStep={nextStep} data={formData} setData={setFormData} prevStep={prevStep} />;
      break;
    case 3:
      stepComponent = <Step3 nextStep={nextStep} data={formData} setData={setFormData} prevStep={prevStep} />;
      break;
    case 4:
      stepComponent = <Step4 nextStep={nextStep} data={formData} setData={setFormData} prevStep={prevStep} />;
      break;
    case 5:
      stepComponent = <Step5 nextStep={nextStep} data={formData} setData={setFormData} prevStep={prevStep} />;
      break;
    case 6:
      //stepComponent = <Step6 nextStep={nextStep} data={formData} setData={setFormData} prevStep={prevStep} />;
      stepComponent = <FinalStep data={formData} nextStep={nextStep} prevStep={prevStep} />;
      break;
    case 7:
      stepComponent = <Submited data={formData} />;
      break;
    default:
    // Handle any other case as needed
  }

  return (

    <FileProvider>
      <div className='timer'>
        <div className="top-right-corner">
          <Timer />
        </div>
      </div>

      <h1><br />3 Mins Cash Layout</h1>
      {stepComponent}
      <br />
      <WhatsAppIcon />

    </FileProvider>
  );
}

export default App;
//{currentStep > 1 && currentStep < 7 && <button className="step_back" onClick={prevStep}>Back</button>}