/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2023-11-03 16:25:36
 * @LastEditTime: 2023-11-06 18:38:56
 * @Description: 
 */
import React, { useEffect, useState } from 'react';

function Step5({ data, setData, nextStep, prevStep }) {
  const handleTextChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  const [copyPhoneToBank, setCopyPhoneToBank] = useState(false);

  const handleNextClick = () => {
    if (isNextButtonEnabled) {
      nextStep();
    } else {
      alert("Please complete all fields before proceeding.");
    }
  }

  const handleCheckboxChange = () => {
    setCopyPhoneToBank(!copyPhoneToBank); // Toggle the checkbox state
    setData({ ...data, bank: data.phoneNumber });
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const isNextButtonEnabled = data.bank && data.phoneNumber;


  return (
    <div class="center">
      <h2>Step 5: Phone Number and Bank Account Information</h2>
      <div class="inputbox">
        <input
          autocomplete="false"
          required="required"
          name="phoneNumber"
          type="number"
          pattern="[0-9]*"
          value={data.phoneNumber}
          onChange={handleTextChange}
        />
        <span>電話號碼</span>
      </div>

      <div class="inputbox">
        <button onClick={handleCheckboxChange}>Copy Phone to FPS</button>
      </div>

      <br />
      <div class="inputbox">
        <input
          autocomplete="false"
          required="required"
          type="text"
          name="bank"
          value={data.bank}
          onChange={handleTextChange}

        />
        <span>FPS ID</span>
      </div>




      <br />
      <button className="step_back" onClick={prevStep}>Back</button>
      {isNextButtonEnabled && <button className="step" onClick={handleNextClick}>Next</button>}
      <br /><br />
    </div>
  );
}

export default Step5;
