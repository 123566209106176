import React, { useState } from 'react';
//import { useNavigate } from 'react-router-dom';
import { saveChoiceToStorage, loadChoiceFromStorage, saveChoiceToStorage2, loadChoiceFromStorage2 } from './ChoiceStorage';

function Step1({ nextStep, data, setData }) {
  const [selectedChoice, setSelectedChoice] = useState(loadChoiceFromStorage() || '');
  const [selectedChoice2, setSelectedChoice2] = useState(loadChoiceFromStorage2() || '');
  //const navigate = useNavigate(); // Initialize navigate

  const handleChoiceChange = (choice) => {
    setData({ ...data, step1Option: choice });
    setSelectedChoice(choice);
  };

  const handleChoiceChange2 = (choice) => {
    setData({ ...data, step1Option2: choice });
    setSelectedChoice2(choice);
  };

  const isNextButtonEnabled = selectedChoice !== '' && selectedChoice2 !== '';

  const handleNext = () => {
    if (isNextButtonEnabled) {
      saveChoiceToStorage(selectedChoice);
      saveChoiceToStorage2(selectedChoice2);
      nextStep();
      //navigate('/next-step'); // Replace '/next-step' with the actual URL of the next step
    } else {
      alert('Please select an option before proceeding.');
    }
  };

  const options = [
    "$10,000",
    "$15,000",
    "$20,000",
    "$25,000",
    "$30,000",
    "$35,000"
  ];

  const options2 = [
    "6期",
    "12期",
    "24期",
    "36期"
  ];

  return (
    <div className="center" >
      <form>
        <h2>Step 1: Select an Option</h2>
        {options.map((option, index) => (
          <div key={index}>
            <input
              type="radio"
              id={option}
              name="options"
              value={option}
              checked={selectedChoice === option}
              onChange={() => handleChoiceChange(option)}
            />
            <label htmlFor={option}>{option}</label>
          </div>
        ))}

      </form>

      <form>
        <h2>Select a repayment</h2>
        {options2.map((option, index) => (
          <div key={index}>
            <input
              type="radio"
              id={option}
              name="options2"
              value={option}
              checked={selectedChoice2 === option}
              onChange={() => handleChoiceChange2(option)}
            />
            <label htmlFor={option}>{option}</label>
          </div>
        ))}

      </form>

      {isNextButtonEnabled && <button className="step" onClick={handleNext}>Next</button>}
    </div>

  );
}

export default Step1;
